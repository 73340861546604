import PropTypes from 'prop-types';
// @mui
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
//
import SearchNotFound from 'src/components/search-not-found';
import { useDispatch, useSelector } from 'react-redux';
import { createConversation } from 'src/redux/features/chats/chatSlice';

// ----------------------------------------------------------------------

export default function ChatNavSearchResults({ query, results, onClickResult }) {

  const dispatch = useDispatch()

  const { createdConversation } = useSelector((state) => state.conversations)

  // Create conversation
  const handleCreateConversation = async (result) => {
    const data = {
      receiver_id: result._id,
    };
    
    const res = await dispatch(createConversation(data));
    console.log(res,'response received')
    onClickResult(res?.payload.body[0]._id)
  };

  const totalResults = results?.length;

  const notFound = !totalResults && !!query;

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          px: 2.5,
          mb: 2,
        }}
      >
        Contacts ({totalResults})
      </Typography>

      {notFound ? (
        <SearchNotFound
          query={query}
          sx={{
            p: 3,
            mx: 'auto',
            width: `calc(100% - 40px)`,
            bgcolor: 'background.neutral',
          }}
        />
      ) : (
        <>
          {results?.map((result) => (
            <ListItemButton
              key={result?._id}
              onClick={() => handleCreateConversation(result)}
              sx={{
                px: 2.5,
                py: 1.5,
                typography: 'subtitle2',
              }}
            >
              <Avatar alt={result?.name} src={result?.image} sx={{ mr: 2 }}>{result?.name?.charAt(0)?.toUpperCase()}</Avatar>
              <Typography variant="subtitle2">
                {result?.name} <br />
                <span style={{ fontSize: '11px', color: 'gray' }}>
                  {result?.email}
                </span>
              </Typography>
            </ListItemButton>
          ))}
        </>
      )}
    </>
  );
}

ChatNavSearchResults.propTypes = {
  query: PropTypes.string,
  results: PropTypes.array,
  onClickResult: PropTypes.func,
};

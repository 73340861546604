import PropTypes from 'prop-types';
import { format, isToday, isThisWeek } from 'date-fns';
import { useState } from 'react';
// import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// components
import Iconify from 'src/components/iconify';
import FileThumbnail from 'src/components/file-thumbnail';
//
import { useSelector } from 'react-redux';
import { useGetMessage } from './hooks';

/* eslint-disable */
export default function ChatMessageItem({ message, participants, onOpenLightbox }) {

  const { currentUser } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);

  const { me, senderDetails, hasImage, hasVideo, hasFile } = useGetMessage({
    message: message?.message,
    sender: message?.sender,
    message_type: message?.message_type,
    currentUserId: `${currentUser?._id}`,
  });

  const { firstName, avatarUrl } = senderDetails;

  const { message: body, timesince: createdAt } = message;


  const formatMessageTime = (timestamp) => {
    const date = new Date(timestamp);
    
  if (isNaN(date.getTime())) {
    return '';
  }
    if (isToday(date)) {
      return format(date, 'hh:mm a');
    }
    if (isThisWeek(date, { weekStartsOn: 1 })) {
      return format(date, 'EEEE HH:mm a');
    }
    return format(date, 'MM/dd/yy HH:mm');
  };


  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const renderInfo = (
    <Typography
      noWrap
      variant="caption"
      sx={{
        mb: 1,
        color: 'text.disabled',
        textTransform: 'capitalize',
        ...(!me && {
          mr: 'auto',
        }),
      }}
    >
      {!me && `${firstName},`} &nbsp;

      <span style={{ textTransform: 'lowercase' }}>
        {formatMessageTime(createdAt)}
      </span>

    </Typography>
  );

  const renderBody = (
    <Stack
      sx={{
        p: 1.5,
        minWidth: 48,
        maxWidth: 320,
        borderRadius: 1,
        typography: 'body2',
        bgcolor: 'background.neutral',
        ...(me && {
          color: 'grey.800',
          bgcolor: 'primary.lighter',
        }),
        ...(hasImage || hasVideo || hasFile ? { p: 0, bgcolor: 'transparent' } : {}),
      }}
    >
      {hasImage && (
        <Box
          component="img"
          alt="attachment"
          src={body}
          onClick={() => onOpenLightbox(body)}
          onLoad={handleImageLoad}
          sx={{
            minHeight: 220,
            borderRadius: 1.5,
            cursor: 'pointer',
            '&:hover': { opacity: 0.9 },
            ...(isLoading ? {} : { border: '1px solid #e4e4e8' }),
          }}
        />
      )}

      {hasVideo && (
        <Box
          component="video"
          controls
          src={body}
          onLoad={handleImageLoad}
          sx={{
            minHeight: 220,
            borderRadius: 1.5,
            cursor: 'pointer',
            '&:hover': { opacity: 0.9 },
            border: isLoading ? 'none':'1px solid #e4e4e8',
            height: '280px',
            width: '200px',
          }}
        />
      )}

      {hasFile && (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1.5,
            borderRadius: 1,
            cursor: 'pointer',
            bgcolor: 'background.paper',
            '&:hover': { bgcolor: 'grey.200' },
            width: '100px',
            height: '100px',
          }}
          onClick={() => {
            const link = document.createElement('a');
            link.href = body;
            link.download = body.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >
          <FileThumbnail
            tooltip
            imageView
            file={body}
            sx={{ width: '100%', height: '100%', margin: '20px' }}
            imgSx={{ width: '100%', height: '100%' }}
          />

          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
            }}
            size="small"
          >
            <Iconify icon="eva:cloud-download-outline" width={24} />
          </IconButton>

          <Typography
            sx={{
              position: 'absolute',
              bottom: -24,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              textAlign: 'center',
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            { message?.fileName ?? <>{body?.split('/')?.pop()?.split('?')[0]?.replace(/%20/g, ' ')}</>}
          </Typography>
        </Box>
      )}

      {!hasImage && !hasVideo && !hasFile && body}
    </Stack>
  );

  const renderActions = (
    <Stack
      direction="row"
      className="message-actions"
      sx={{
        pt: 0.5,
        opacity: 0,
        top: '100%',
        left: 0,
        position: 'absolute',
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        ...(me && {
          left: 'unset',
          right: 0,
        }),
      }}
    >
      {/* <IconButton size="small">
        <Iconify icon="solar:reply-bold" width={16} />
      </IconButton>
      <IconButton size="small">
        <Iconify icon="eva:smiling-face-fill" width={16} />
      </IconButton>
      <IconButton size="small">
        <Iconify icon="solar:trash-bin-trash-bold" width={16} />
      </IconButton> */}
    </Stack>
  );

  return (
    <Stack direction="row" justifyContent={me ? 'flex-end' : 'unset'} sx={{ mb: 5 }}>
      {!me && <Avatar alt={firstName?.charAt(0)?.toUpperCase()} src={avatarUrl} sx={{ width: 32, height: 32, mr: 2 }}>{firstName?.charAt(0)?.toUpperCase()}</Avatar>     }

      <Stack alignItems="flex-start">
        {renderInfo}

        <Stack
          direction="row"
          alignItems="center"
          sx={{
            position: 'relative',
            '&:hover': {
              '& .message-actions': {
                opacity: 1,
              },
            },
          }}
        >
          {renderBody}
          {renderActions}
        </Stack>
      </Stack>
    </Stack>
  );
}

ChatMessageItem.propTypes = {
  message: PropTypes.object,
  onOpenLightbox: PropTypes.func,
  participants: PropTypes.array,
};

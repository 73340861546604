import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;
// add board
const addBoard = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/board/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Add Board api failed");
};

// Get Board
const boardGet = async (token, project, pageno, limit) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/board/${project}?page=${pageno}&perPage=${limit}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Board List api failed");
};

// Update Board
const updateBoard = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/board/${id}`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Updated Business Type api failed");
};

// Active Board
const activeBoardGet = async (token, pageno, limit, search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/active/board?page=${pageno}&perPage=${limit}&search=${search || ""}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Board Active List api failed");
};

// Get Single Board 
const singleBoardGet = async (token, board) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/single/${board}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Single Board api failed");
};

// Transfer Board Api
const transferBoard = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/boardTranfer/${id}`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Transfer Board api failed");
};

// Report Board oF Task
const reportBoardGet = async (token, boardId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/board/report/${boardId}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Report Board api failed");
};

// Favorite Board Get
const favoriteBoardGet = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/favorite/board`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Favorite Board List api failed");
};
// Board Like Button           
const boardLikeToggle = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/board/favorite`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Board Like Toggle api failed');
};
// Get Board  Task Due Date
const boardTaskDueDateGet = async (token, boardId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/task/${boardId}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Board Task Due Date api failed");
};
// Get User Board
const userBoardGet = async (token, project) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/user/board/${project}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("User Board List api failed");
};
// Report Board oF Label
const reportBoardLabelGet = async (token, boardId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/label/report/${boardId}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Board label report api failed");
};
// update the board task due dates
const updateBoardTaskDueDate = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/task/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Board Task Due Date api failed');
};


// Shared Board
const sharedBoard = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/board-shared`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Board Shared api failed');
};

const boardServices = {
  addBoard,
  boardGet,
  updateBoard,
  activeBoardGet,
  singleBoardGet,
  transferBoard,
  reportBoardGet,
  favoriteBoardGet,
  boardLikeToggle,
  boardTaskDueDateGet,
  userBoardGet,
  reportBoardLabelGet,
  updateBoardTaskDueDate,
  sharedBoard
};

export default boardServices;

import { useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// components
import Scrollbar from 'src/components/scrollbar';
import Lightbox, { useLightBox } from 'src/components/lightbox';
//
import { CircularProgress, Typography } from '@mui/material';
import { useMessagesScroll } from './hooks';
import ChatMessageItem from './chat-message-item';
// import { getConversationById } from 'src/redux/features/chats/chatSlice';

/* eslint-disable */
export default function ChatMessageList({ messages = [], participants}) {

  const { messagesEndRef } = useMessagesScroll(messages);
  const slides = messages
    .filter((message) => message?.message_type === 'image')
    .map((message) => ({ src: message?.message }));

  const lightbox = useLightBox(slides);

  return (
    <>
      <Scrollbar ref={messagesEndRef} sx={{ px: 3, py: 5, height: 1 }}>
      <Box>
        {messages?.length > 0 ? messages?.map((message, idx) => {
           return (
          <ChatMessageItem
            key={idx}
            message={message}
            participants={participants}
            onOpenLightbox={() => lightbox.onOpen(message?.message)}
          />
           );
      }) : (
          <Typography textAlign='center' mb={5} color='gray'>
            {/* No messages in this conversation yet. Start the conversation by sending a message. */}
          </Typography>
        )}
      </Box>
      </Scrollbar>

      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
      />
    </>
  );
}

ChatMessageList.propTypes = {
  messages: PropTypes.array,
  participants: PropTypes.array,
};

import PropTypes from 'prop-types';
import { useCallback,forwardRef} from 'react';
// import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// import AvatarGroup from '@mui/material/AvatarGroup';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { useNavigate } from 'react-router-dom';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// api
// import { clickConversation } from 'src/api/chat';
//
import { useSelector } from 'react-redux';
// import { getConversationById } from 'src/redux/features/chats/chatSlice';
import { useGetNavItem } from './hooks';

/* eslint-disable */
const  ChatNavItem = forwardRef(({
  selected,
  collapse,
  conversation,
  onCloseMobile,
  viewContact
}, ref) => {
  const { currentUser: user } = useSelector((state) => state.auth);

  const mdUp = useResponsive('up', 'md');

  const navigate = useNavigate();

  // const dispatch = useDispatch()

  // const { singleConversation, isLoading } = useSelector((state) => state.conversations)

  const router = useRouter();

  const { name } = conversation;

  const { group, displayName, displayText, participants, lastActivity, hasOnlineInGroup } =
    useGetNavItem({
      conversation,
      currentUser: user,
    });

  // const singleParticipant = participants[0];

  // const { name, avatarUrl, status } = singleParticipant;

  // console.warn("singleConversation >>> ", singleConversation)
  

  const handleClickConversation = useCallback(async () => {
    console.log(viewContact,"viewContact")
    try {
      if (!mdUp) {
        onCloseMobile();
      }if(viewContact){
        let pathName = window.location.pathname;
        navigate(`${pathName}`,{state:{id:conversation.conversation_id}});
        return;
      }
      router.push(`${paths.dashboard.chat}?id=${conversation.conversation_id}`);
    } catch (error) {
      console.error(error);
    }
  }, [conversation, mdUp, onCloseMobile, router, navigate, viewContact]);

  const renderGroup = (
    <>
      <Avatar src={conversation?.image} sx={{ mr: 2 }}>
        {conversation?.title?.charAt(0)?.toUpperCase()}
      </Avatar>
    </>
  );

  const renderSingle = (
    <>
      <Avatar
        src={
          conversation?.sender_id === user?._id
            ? conversation?.reciever_image
            : conversation?.sender_image
        }
        sx={{ mr: 2 }}
      >
        {conversation?.sender_id === user?._id
          ? conversation?.reciever_name?.charAt(0)?.toUpperCase()
          : conversation?.sender_name?.charAt(0)?.toUpperCase()}
      </Avatar>
    </>
  );

  return (
    <ListItemButton
      ref={ref}
      disableGutters
      onClick={handleClickConversation}
      sx={{
        py: 1.5,
        px: 2.5,
        ...(selected && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <Badge
        color="error"
        overlap="circular"
        badgeContent={collapse ? conversation?.unread_count : 0}
      >
        {group ? renderGroup : renderSingle}
      </Badge>

      {!collapse && (
        <>
          <ListItemText
            // sx={{ ml: 2 }}
            primary={
              <div style={{ textTransform: 'capitalize' }}>
                {group
                  ? conversation?.title
                  : conversation?.sender_id === user?._id
                  ? conversation?.reciever_name
                  : conversation?.sender_name}
              </div>
            }
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondary={displayText}
            secondaryTypographyProps={{
              noWrap: true,
              component: 'span',
              variant: conversation.unreadCount ? 'subtitle2' : 'body2',
              color: conversation.unreadCount ? 'text.primary' : 'text.secondary',
            }}
          />

          <Stack alignItems="flex-end" sx={{ ml: 2, height: 40 }}>
            {(displayText || lastActivity) && (
              <Typography
                noWrap
                variant="body2"
                component="span"
                sx={{
                  fontSize: 12,
                  color: 'text.disabled',
                }}
              >
                {lastActivity}
              </Typography>
            )}

            {!!conversation?.unread_count && (
              <Badge
                badgeContent={conversation?.unread_count}
                sx={{
                  '& .MuiBadge-badge': {
                    right: '9px',
                    top: '9px',
                    fontSize: 12,
                    height: 20,
                    minWidth: 20,
                    borderRadius: '50%',
                    backgroundColor: '#1976d2',
                    color: 'white',
                  },
                }}
              />
            )}
          </Stack>
        </>
      )}
    </ListItemButton>
  );
});

ChatNavItem.propTypes = {
  collapse: PropTypes.bool,
  conversation: PropTypes.object,
  onCloseMobile: PropTypes.func,
  selected: PropTypes.bool,
};
export default ChatNavItem;
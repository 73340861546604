import PropTypes from 'prop-types';
import 'src/utils/highlight';
import ReactQuill from 'react-quill';
import imageCompression from 'browser-image-compression';
// @mui
import { alpha } from '@mui/material/styles';
import Portal from '@mui/material/Portal';
import Backdrop from '@mui/material/Backdrop';
import {useState, useCallback, useEffect } from 'react';
// Import your styled editor and toolbar components
import { StyledEditor } from './styles';
import Toolbar, { formats } from './toolbar';

/* eslint-disable */
export default function Editor({
  id = 'minimal-quill',
  error,
  simple = false,
  helperText,
  sx,
  ...other
}) {
  const [fullScreen, setFullScreen] = useState(false);

  const handleToggleFullScreen = useCallback(() => {
    setFullScreen((prev) => !prev);
  }, []);


  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  useEffect(() => {
    if (fullScreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [fullScreen]);

  return (
    <>
      <Portal disablePortal={!fullScreen}>
        {fullScreen && <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.modal - 1 }} />}
        <StyledEditor
          sx={{
            ...(error && {
              border: (theme) => `solid 1px ${theme.palette.error.main}`,
              '& .ql-editor': {
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
              },
            }),
            ...sx,
            ...(fullScreen && {
              position: 'fixed',
              top: "10%",
              left: "10%",
              width: '80vw',
              height: '80vh',
              zIndex: 1300, 
              bgcolor: 'background.paper', 
              '& .quill': {
                height: 'calc(100vh - 40px) !important',
                overflowY: 'auto',
              },
              '& .ql-editor': {
                minHeight: '100% !important', 
                overflowY: 'auto',
              },
            }),
          }}
        >
          <Toolbar id={id} isSimple={simple} onFullScreenToggle={handleToggleFullScreen} fullScreen={fullScreen} />

          <ReactQuill
            modules={modules}
            formats={formats}
            placeholder="Write something awesome..."
            {...other}
          />
        </StyledEditor>

        {helperText && helperText}
      </Portal>
    </>
  );
}

Editor.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  simple: PropTypes.bool,
  sx: PropTypes.object,
};

import PropTypes from 'prop-types';
import { sub } from 'date-fns';
import { useRef, useState, useCallback, useMemo,forwardRef } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// utils
import uuidv4 from 'src/utils/uuidv4';
// api
import { sendMessage, createConversation } from 'src/api/chat';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { useSelector,useDispatch } from 'react-redux';
import { uploadConversatiionFile } from 'src/redux/features/uploadFile/uploadFileSlice';
import { formatToReadableDateTime } from 'src/utils/format-time';


const  ChatMessageInput = forwardRef(({
    recipients,
  onAddRecipients,
  //
  disabled,
  selectedConversationId,
  handleSendMessage,
  setMessage,
  message,
  handleSendImage,
  setConversationData
  }, ref) => {
  const router = useRouter();

  const { currentUser: user } = useSelector((state) => state.auth)
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();


  const fileRef = useRef(null);

  const myContact = useMemo(
    () => ({
      id: `${user?._id}`,
      // role: `${user?.role}`,
      email: `${user?.email}`,
      // address: `${user?.address}`,
      name: `${user?.name}`,
      lastActivity: new Date(),
      // avatarUrl: `${user?.photoURL}`,
      // phoneNumber: `${user?.phoneNumber}`,
      status: 'online',
    }),
    [user]
  );

  // const messageData = useMemo(
  //   () => ({
  //     id: uuidv4(),
  //     attachments: [],
  //     body: message,
  //     contentType: 'text',
  //     createdAt: sub(new Date(), { minutes: 1 }),
  //     senderId: myContact.id,
  //   }),
  //   [message, myContact.id]
  // );

  // const conversationData = useMemo(
  //   () => ({
  //     id: uuidv4(),
  //     messages: [messageData],
  //     participants: [...recipients, myContact],
  //     type: recipients.length > 1 ? 'GROUP' : 'ONE_TO_ONE',
  //     unreadCount: 0,
  //   }),
  //   [messageData, myContact, recipients]
  // );

  const handleFileChange = async(event) => {
    const file = event.target.files[0];
    if (file?.size > 1 * 1024 * 1024) {
      enqueueSnackbar("File size should not exceed 1 MB.", {
        variant: 'error',
      });
      return;
    }
    if (file) {
      let fileType = 'file';
      if (file?.type?.startsWith('image/')) {
        fileType = 'image';
      } else if (file?.type?.startsWith('video/')) {
        fileType = 'video';
      }
      setConversationData(prevData => {
        const newMessages = [
          ...prevData,
          {
            message: URL.createObjectURL(file),
            message_type: fileType,
            fileName:file?.name,
            sender: {
              _id: user?._id,
              name: user?.name
            },
            timestamp: new Date().toISOString(),
            timesince: formatToReadableDateTime(new Date())
          }
        ];
  
        return newMessages;
      });
      const formData = new FormData();
      formData.append('file', file);
      formData.append('conversatiionId', selectedConversationId);
      const response = await dispatch(uploadConversatiionFile(formData));
      if (response?.type === "uploadConversatiionFile/fulfilled") {
        handleSendImage(response?.payload?.body?.fileName,fileType);
      }
    }
  };

  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const handleChangeMessage = useCallback((event) => {
    setMessage(event.target.value);
  }, [setMessage]);


  return (
    <>
      <InputBase
        inputRef={ref}
        value={message}
        onKeyUp={handleSendMessage}
        onChange={handleChangeMessage}
        placeholder="Type a message"
        disabled={disabled}
        // startAdornment={
        //   <IconButton>
        //     <Iconify icon="eva:smiling-face-fill" />
        //   </IconButton>
        // }
        endAdornment={
          <Stack direction="row" sx={{ flexShrink: 0 }}>
            <IconButton onClick={handleAttach}>
              <Iconify icon="solar:gallery-add-bold" />
            </IconButton>
            {/* <IconButton onClick={handleAttach}>
              <Iconify icon="eva:attach-2-fill" />
            </IconButton> */}
            {/* <IconButton>
              <Iconify icon="solar:microphone-bold" />
            </IconButton> */}
          </Stack>
        }
        sx={{
          px: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
          width: 1,
        }}
      />

      <input type="file"  ref={fileRef} style={{ display: 'none' }} onChange={handleFileChange} />
    </>
  );
})

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  onAddRecipients: PropTypes.func,
  recipients: PropTypes.array,
  selectedConversationId: PropTypes.string,
  handleSendMessage: PropTypes.func,
  setMessage: PropTypes.func,
  message: PropTypes.string,
  handleSendImage:PropTypes.func,
  setConversationData:PropTypes.func,
};

export default ChatMessageInput;
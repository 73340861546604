import { format, isToday, isThisWeek } from 'date-fns';

/* eslint-disable */
export default function useGetNavItem({ currentUser, conversation }) {

  // const participantsInConversation = participants?.filter(
  //   (participant) => participant.id !== currentUserId
  // );

  // console.log("conversation >>>> ", conversation)

  const lastMessage = conversation?.message;

  const group = conversation?.chatType === 'group';

  // const displayName = participantsInConversation?.map((participant) => participant.name).join(', ');

  // const hasOnlineInGroup = group
  //   ? participantsInConversation?.map((item) => item.status).includes('online')
  //   : false;

  let displayText = '';

  if (lastMessage) {
    const sender = conversation?.sender_id !== currentUser?._id ? 'You: ' : '';

    const message = conversation?.message_type === 'image'
    ? 'Sent a photo'
    : conversation?.message_type === 'video'
    ? 'Sent a video'
    : conversation?.message_type === 'file'
    ? 'Sent a file'
    : lastMessage;

    // displayText = `${sender}${message}`;
    displayText = `${message}`;
  }
  
   // Format lastActivity based on the date
   const formatLastActivity = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isToday(date)) {
      return format(date, 'hh:mm a');
    }
    if (isThisWeek(date, { weekStartsOn: 1 })) {
      return format(date, 'EEEE');
    }
    return format(date, 'MM/dd/yy');
  };

  return {
    group,
    // displayName,
    displayText,
    // participants: participantsInConversation,
    lastActivity: formatLastActivity(conversation?.timesince),
    // hasOnlineInGroup,
  };
}

import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
// _mock
import { _contacts } from 'src/_mock';

import { useSelector, useDispatch } from 'react-redux';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import SearchNotFound from 'src/components/search-not-found';
import { searchContacts } from 'src/redux/features/auth/authSlice';
import { uploadChatGroupProfile } from 'src/redux/features/uploadFile/uploadFileSlice';
import { createGroup, getAllConversation } from 'src/redux/features/chats/chatSlice';
import { formatToReadableDateTime } from 'src/utils/format-time';

const ITEM_HEIGHT = 64;

/* eslint-disable */
export default function ChatAddMember({ open, onClose, conversations, createGroupData,setConversations}) {
  const [searchContact, setSearchContact] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { currentUser, contacts } = useSelector((state) => state.auth);
  const {isLoadingAddGroup} = useSelector((state) => state.conversations);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  const handleSearchContacts = useCallback((event) => {
    setSearchContact(event.target.value);
  }, []);

  useEffect(() => {
    if (searchContact) {
      dispatch(searchContacts({ token: currentUser.accesstoken, search: searchContact }));
    } else if (conversations && currentUser) {
      const filteredUsers = conversations?.filter((conversation) => conversation?.chatType !='group')?.map((conversation) => ({
        _id:
          conversation.sender_id === currentUser?._id
            ? conversation.reciever_id
            : conversation.sender_id,
        name:
          conversation.sender_id === currentUser?._id
            ? conversation.reciever_name
            : conversation.sender_name,
        email:
          conversation.sender_id === currentUser?._id
            ? conversation.reciever_email
            : conversation.sender_email,
        image:
          conversation.sender_id === currentUser?._id
            ? conversation.reciever_image
            : conversation.sender_image,
      }));
      setUsers(filteredUsers);
    }
  }, [conversations, currentUser, searchContact, dispatch]);

  useEffect(() => {
    if (searchContact && contacts && contacts?.body) {
      const allContacts = contacts?.body?.map((contact) => ({
        _id: contact?._id,
        name: contact?.name,
        email: contact?.email,
        image: contact?.image || '',
      }));
      setUsers(allContacts);
    }
  }, [contacts, searchContact]);

  const dataFiltered = applyFilter({
    inputData: users,
    query: searchContact,
  });

  const notFound = !dataFiltered.length && !!searchContact;

  const handleToggleUser = (user) => {
    setSelectedUsers((prevSelected) => {
      const isSelected = prevSelected.some((selectedUser) => selectedUser._id === user._id);
      if (isSelected) {
        return prevSelected.filter((selectedUser) => selectedUser._id !== user._id);
      } else {
        return [...prevSelected, user];
      }
    });
  };


  const handleSubmit = async () => {
    const selectedUserIds = selectedUsers.map(user => user._id);
     
    if(createGroupData?.avatarUrl){
      const formData = new FormData();
      formData.append('file', createGroupData?.avatarUrl);
      const response = await dispatch(uploadChatGroupProfile(formData))
      if (response?.type === 'uploadChatGroupProfile/fulfilled') {
       const newData ={
          title:createGroupData.title,
          members:selectedUserIds,
          image: response?.payload?.body?.fileName
     }
     const responseCreateGroup = await dispatch(createGroup(newData));
     if (responseCreateGroup?.type === 'createGroup/fulfilled') {
      const newGroup = responseCreateGroup?.payload?.body;
      setConversations((prevConversations) => [
        { ...newGroup,conversation_id:newGroup._id,
          groupMembers:newGroup.members,chatType:newGroup.chat_type,unread_count: 0,image:response?.payload?.body?.path,timesince:formatToReadableDateTime(new Date())},
        ...prevConversations,
      ]);
      onClose();
     }
   }
     
  }
    else{
      const newData ={
        title:createGroupData.title,
        members:selectedUserIds,
    }
      const responseCreateGroup = await dispatch(createGroup(newData));
      if (responseCreateGroup?.type === 'createGroup/fulfilled') {
        onClose();
        const newGroup = responseCreateGroup?.payload?.body;
        setConversations((prevConversations) => [
          { ...newGroup,conversation_id:newGroup._id,groupMembers:newGroup.members,chatType:newGroup.chat_type, unread_count: 0,timesince:formatToReadableDateTime(new Date())},
          ...prevConversations,
        ]);
    }
    }
};

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 0, textAlign: 'center' }}>Create new group</DialogTitle>

      <Box sx={{ px: 3, py: 2.5 }}>
        <TextField
          fullWidth
          value={searchContact}
          onChange={handleSearchContacts}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Display Selected Users */}
      <Box
        sx={{
          px: 3,
          py: 1.5,
          display: 'flex',
          gap: 2,
        }}
      >
        {selectedUsers.map((userId) => {
          return (
            <Box
              key={userId}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Avatar src={userId?.image} alt={userId?.name} sx={{ width: 40, height: 40 }} />
                <IconButton
                  size="small"
                  sx={{
                    zIndex: 1,
                    padding: '0px',
                    marginBottom: '20px',
                  }}
                  onClick={() => handleToggleUser(userId)}
                >
                  <Iconify icon="eva:close-fill" width={15} height={15} />
                </IconButton>
              </Box>

              <Typography variant="subtitle2" align="center">
                {userId?.name}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <DialogContent sx={{ p: 0 }}>
        {notFound ? (
          <SearchNotFound query={searchContact} sx={{ mt: 3, mb: 10 }} />
        ) : (
          <Scrollbar
            sx={{
              px: 2.5,
              height: ITEM_HEIGHT * 6,
            }}
          >
            {dataFiltered.map((contact) => {
              return (
                <ListItem
                  key={contact?._id}
                  disableGutters
                  secondaryAction={
                    <Checkbox
                      checked={selectedUsers.some(
                        (selectedUser) => selectedUser._id === contact._id
                      )}
                      onChange={() => handleToggleUser(contact)}
                    />
                  }
                  sx={{ height: ITEM_HEIGHT }}
                >
                  <ListItemAvatar>
                    <Avatar src={contact?.image} alt={contact?.name}>
                      {contact?.name?.charAt(0)?.toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primaryTypographyProps={{
                      typography: 'subtitle2',
                      sx: { mb: 0.25 },
                    }}
                    secondaryTypographyProps={{ typography: 'caption' }}
                    primary={contact?.name}
                    secondary={contact?.email}
                  />
                </ListItem>
              );
            })}
          </Scrollbar>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 0, justifyContent: 'center' }}>
        <LoadingButton sx={{ marginBottom: '12px' }} onClick={handleSubmit} loading={isLoadingAddGroup}>
          Done
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

ChatAddMember.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  conversations: PropTypes.array,
  createGroupData: PropTypes.object,
  setConversations:PropTypes.func
};

function applyFilter({ inputData, query }) {
  if (query) {
    inputData = inputData.filter(
      (contact) =>
        contact.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        contact.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return inputData;
}

import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup';
// components
import Iconify from 'src/components/iconify';
import { useSelector } from 'react-redux';

export default function ChatHeaderDetail({ conversation }) {

  const { currentUser: user } = useSelector((state) => state.auth)

  const group = conversation?.chatType === 'group';

  const renderGroup = (
    <Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
        <Avatar src={conversation?.image}>
           {conversation?.title?.charAt(0)?.toUpperCase()}
        </Avatar>
      
      <ListItemText
        primary={<div style={{textTransform:'capitalize'}}>{conversation?.title}</div>}
        secondary={`${conversation?.groupMembers?.length} participants`}
        secondaryTypographyProps={{
          component: 'span',
          style: { fontSize: '0.75rem' },
        }}
      />
    </Stack>

  );

  const renderSingle = (
    <Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
      {/* <Badge
        variant='online'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        </Badge> */}
        <Avatar src={conversation?.sender_id === user?._id ? conversation?.reciever_image : conversation?.sender_image}>
        {conversation?.sender_id === user?._id ? conversation?.reciever_name?.charAt(0)?.toUpperCase() : conversation?.sender_name?.charAt(0)?.toUpperCase()}  
        </Avatar>
      

      <ListItemText
        primary={<div style={{textTransform:'capitalize'}}>{conversation?.sender_id === user?._id ? conversation?.reciever_name : conversation?.sender_name}</div>}
        // secondary={
        //   singleParticipant.status === 'offline'
        //     ? fToNow(singleParticipant.lastActivity)
        //     : singleParticipant.status
        // }
        secondary={conversation?.sender_id === user?._id ? conversation?.reciever_email : conversation?.sender_email}
        secondaryTypographyProps={{
          component: 'span',
          // ...(singleParticipant.status !== 'offline' && {
          //   textTransform: 'capitalize',
          // }),
        }}
      />
    </Stack>
  );

  return (
    <>
      {group ? renderGroup : renderSingle}

      <Stack flexGrow={1} />

      {/* <IconButton>
        <Iconify icon="solar:phone-bold" />
      </IconButton>
      <IconButton>
        <Iconify icon="solar:videocamera-record-bold" />
      </IconButton>
      <IconButton>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton> */}
    </>
  );
}

ChatHeaderDetail.propTypes = {
  conversation: PropTypes.object,
};

// import * as Yup from 'yup';
// import PropTypes from 'prop-types';
// import { useState, useEffect, useCallback,useMemo,useRef} from 'react';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// // @mui
// import { useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
// import Drawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import Button from '@mui/material/Button';

// // hooks
// import { useResponsive } from 'src/hooks/use-responsive';
// // routes
// import { paths } from 'src/routes/paths';
// import { useRouter } from 'src/routes/hooks';
// // components
// import Iconify from 'src/components/iconify';
// import Scrollbar from 'src/components/scrollbar';
// import FormProvider, {
//   RHFUploadAvatar,
//   RHFTextField
// } from 'src/components/hook-form';

// import { searchContacts } from 'src/redux/features/auth/authSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAllConversation } from 'src/redux/features/chats/chatSlice';
// import { useCollapseNav } from './hooks';
// import ChatNavItem from './chat-nav-item';
// import ChatNavAccount from './chat-nav-account';
// import { ChatNavItemSkeleton } from './chat-skeleton';
// import ChatNavSearchResults from './chat-nav-search-results';
// import ChatAddMember from './chat-add-members';

// const NAV_WIDTH = 320;

// const NAV_COLLAPSE_WIDTH = 96;

// /* eslint-disable */
// export default function ChatNav({ loading, conversations, selectedConversationId, singleConversation,setConversations}) {
//   console.log(conversations)

//   const [searchcontacts, setsearchcontacts] = useState({ query: '', results: [] });
//   const [openDialog, setOpenDialog] = useState(false);
//   const [addMemberDialog, setAddMemberDialog] = useState(false);
//   const [createGroupData, setCreateGroupData] = useState(null);
//   const [lastObservedMultiple, setLastObservedMultiple] = useState(0); 
//   const [limit, setLimit] = useState(20);

//   const dispatch = useDispatch();
//   const observerRef = useRef(null);

//   const { currentUser, contacts } = useSelector((state) => state.auth)
//   const allContacts = contacts?.body



//   useEffect(() => {
//     dispatch(getAllConversation(limit));
//   }, [dispatch, limit]);

//   const increaseLimit = (multiple) => {
//     setLastObservedMultiple((prevLastObserved) => {
//         if (multiple > prevLastObserved) {
//         setLimit((prevLimit) => prevLimit + 20);
//         return multiple;
//       }
//       return prevLastObserved;
//     });
//   };
//   const NewUserSchema = Yup.object().shape({
//      avatarUrl: Yup.mixed().nullable(),
//      title: Yup.string().required('title is required'),
//   });

//   const defaultValues = useMemo(
//     () => ({
//       avatarUrl:null,
//       title:'',
//     }),
//     []
//   );
//   const methods = useForm({
//     resolver: yupResolver(NewUserSchema),
//     defaultValues,
//   });
//   const {
//     setValue,
//     handleSubmit,
//     reset,
//     formState: { isSubmitting },
//   } = methods;


//   useEffect(() => {
//     if (currentUser?.accesstoken) {
//       dispatch(searchContacts({ token: currentUser.accesstoken, search: searchcontacts.query }))
//     }
//   }, [dispatch, currentUser, searchcontacts.query])

//   const theme = useTheme();

//   const router = useRouter();

//   const mdUp = useResponsive('up', 'md');

//   const {
//     collapseDesktop,
//     onCloseDesktop,
//     onCollapseDesktop,
//     openMobile,
//     onOpenMobile,
//     onCloseMobile,
//   } = useCollapseNav();


//   useEffect(() => {
//     if (!mdUp) {
//       onCloseDesktop();
//     }
//   }, [onCloseDesktop, mdUp]);

//   const handleToggleNav = useCallback(() => {
//     if (mdUp) {
//       onCollapseDesktop();
//     } else {
//       onCloseMobile();
//     }
//   }, [mdUp, onCloseMobile, onCollapseDesktop]);

//   const handleClickCompose = useCallback(() => {
//     if (!mdUp) {
//       onCloseMobile();
//     }
//     router.push(paths.dashboard.chat);
//   }, [mdUp, onCloseMobile, router]);

//   const handleSearchContacts = useCallback((inputValue) => {
//       setsearchcontacts((prevState) => ({
//         ...prevState,
//         query: inputValue,
//       }));

//       if (inputValue) {
//         const results = allContacts?.filter((contact) =>
//           contact.name.toLowerCase().includes(inputValue) ||
//           contact.email.toLowerCase().includes(inputValue)
//         );

//         setsearchcontacts((prevState) => ({
//           ...prevState,
//           results,
//         }));
//       }
//     }, [allContacts]
//   );

//   const handleClickAwaySearch = useCallback(() => {
//     setsearchcontacts({
//       query: '',
//       results: [],
//     });
//   }, []);

//   const handleClickResult = useCallback(
//     (result) => {
//       handleClickAwaySearch();
//       router.push(`${paths.dashboard.chat}?id=${result}`);
//       dispatch(getAllConversation())
//     },
//     [handleClickAwaySearch, router, dispatch]
//   );


//   const handleOpenDialog = useCallback(() => {
//     setOpenDialog(true);
//   }, []);

//   const handleCloseDialog = async() => {
//     reset();
//     setOpenDialog(false);
//   };


//   const handleOpenAddMemberDialog = useCallback(() => {
//     setAddMemberDialog(true);
//   }, []);

//   const handleCloseAddMemberDialog= useCallback(() => {
//     setAddMemberDialog(false);
//   }, []);

//   const onSubmit = async (data) => {
//     setCreateGroupData(data);
//     handleCloseDialog();
//     handleOpenAddMemberDialog();
//   };

//     const handleDrop = useCallback(
//       (acceptedFiles) => {
//         const file = acceptedFiles[0];

//         const newFile = Object.assign(file, {
//           preview: URL.createObjectURL(file),
//         });

//         if (file) {
//           setValue('avatarUrl', newFile, { shouldValidate: true });
//         }
//       },
//       [setValue]
//     );


//     const setupObserver = useCallback((node, multiple) => {
//       if (!observerRef.current) {
//         observerRef.current = new IntersectionObserver(
//           (entries) => {
//             entries?.forEach((entry) => {
//               if (entry.isIntersecting) {
//                 increaseLimit(multiple);
//               }
//             });
//           },
//           {
//             root: null, 
//             rootMargin: '0px',
//             threshold: 1.0,
//           }
//         );
//       }
//       if (node) {
//         observerRef.current.observe(node);
//       }
//     }, [lastObservedMultiple]);

//   const renderToggleBtn = (
//     <IconButton
//       onClick={onOpenMobile}
//       sx={{
//         left: 0,
//         top: 84,
//         zIndex: 9,
//         width: 32,
//         height: 32,
//         position: 'absolute',
//         borderRadius: `0 12px 12px 0`,
//         bgcolor: theme.palette.primary.main,
//         boxShadow: theme.customShadows.primary,
//         color: theme.palette.primary.contrastText,
//         '&:hover': {
//           bgcolor: theme.palette.primary.darker,
//         },
//       }}
//     >
//       <Iconify width={16} icon="solar:users-group-rounded-bold" />
//     </IconButton>
//   );

//   const renderSkeleton = (
//     <>
//       {[...Array(12)]?.map((_, index) => (
//         <ChatNavItemSkeleton key={index} />
//       ))}
//     </>
//   );

//   const renderList = (
//     <>
//       {Array.isArray(conversations) &&  conversations?.map((conversation,index) => {
//        const isMultipleOfTwelve = (index + 1) % 12 === 0;
//        const multiple = (index + 1) / 12;
//         return (
//         <ChatNavItem
//           key={conversation?.conversation_id}
//           collapse={collapseDesktop}
//           conversation={conversation}
//           selected={conversation?.conversation_id === selectedConversationId}
//           onCloseMobile={onCloseMobile}
//           ref={isMultipleOfTwelve ? (node) => setupObserver(node, multiple) : null}
//         />
//         );
//       })}
//     </>
//   );

//   const renderListResults = (
//     <ChatNavSearchResults
//       query={searchcontacts?.query}
//       results={searchcontacts?.results}
//       onClickResult={handleClickResult}
//     />
//   );

//   const renderSearchInput = (
//     <ClickAwayListener onClickAway={handleClickAwaySearch}>
//       <TextField
//         fullWidth
//         value={searchcontacts.query}
//         onChange={(event) => handleSearchContacts(event.target.value)}
//         placeholder="Search contacts..."
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
//             </InputAdornment>
//           ),
//         }}
//         sx={{ mt: 2.5 }}
//       />
//     </ClickAwayListener>
//   );

//   const renderContent = (
//     <>
//       <Stack direction="row" alignItems="center" justifyContent="center" sx={{ p: 2.5, pb: 0 }}>
//         {!collapseDesktop && (
//           <>
//             <ChatNavAccount />
//             <Box sx={{ flexGrow: 1 }} />
//           </>
//         )}

//         {/* <IconButton onClick={handleToggleNav}>
//           <Iconify
//             icon={collapseDesktop ? 'eva:arrow-ios-forward-fill' : 'eva:arrow-ios-back-fill'}
//           />
//         </IconButton> */}


//           <IconButton onClick={handleOpenDialog}>
//             <Iconify width={24} icon="ic:round-group-add" />
//           </IconButton>
//       </Stack>

//       <Box sx={{ p: 2.5, pt: 0 }}>{!collapseDesktop && renderSearchInput}</Box>

//       <Scrollbar sx={{ pb: 1 }}    >
//         {searchcontacts.query && renderListResults}

//         {/* {loading && renderSkeleton} */}

//         {!searchcontacts.query && !!conversations?.length && renderList}
//       </Scrollbar>
//     </>
//   );

//   return (
//     <>
//       {!mdUp && renderToggleBtn}

//       {mdUp ? (
//         <Stack
//           sx={{
//             height: 1,
//             flexShrink: 0,
//             width: NAV_WIDTH,
//             borderRight: `solid 1px ${theme.palette.divider}`,
//             transition: theme.transitions.create(['width'], {
//               duration: theme.transitions.duration.shorter,
//             }),
//             ...(collapseDesktop && {
//               width: NAV_COLLAPSE_WIDTH,
//             }),
//           }}
//         >
//           {renderContent}
//         </Stack>
//       ) : (
//         <Drawer
//           open={openMobile}
//           onClose={onCloseMobile}
//           slotProps={{
//             backdrop: { invisible: true },
//           }}
//           PaperProps={{
//             sx: { width: NAV_WIDTH },
//           }}
//         >
//           {renderContent}
//         </Drawer>
//       )}

//       {/* Dialog for creating a new group */}
//       <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xs" sx={{}} fullWidth>
//         <DialogTitle sx={{ pb: 0, textAlign: 'center' }}>New Group Chat</DialogTitle>
//         <DialogContent>
//           <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//             <Stack direction="column" alignItems="center" spacing={5} sx={{ marginTop: '20px' }}>
//               <Box
//                 sx={{
//                   width: 100,
//                   height: 100,
//                   borderRadius: '50%',
//                   bgcolor: theme.palette.grey[200],
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   marginTop: '20px',
//                 }}
//               >
//                 <RHFUploadAvatar name="avatarUrl" onDrop={handleDrop} />
//               </Box>
//               <RHFTextField fullWidth name="title" label="Group name" variant="outlined" />
//               <IconButton
//                 variant="contained"
//                 type="submit"
//                 sx={{
//                   alignSelf: 'flex-end',
//                   bgcolor: 'black',
//                   '&:hover': {
//                     bgcolor: 'black',
//                   },
//                   marginBottom: '20px',
//                 }}
//               >
//                 <Iconify width={24} icon="mdi:arrow-right" sx={{ color: 'white' }} />
//               </IconButton>
//             </Stack>
//           </FormProvider>
//         </DialogContent>
//       </Dialog>

//       {/* Dialog for creating a new group for add members */}
//       <ChatAddMember open={addMemberDialog}  onClose={handleCloseAddMemberDialog}  conversations={conversations} setConversations={setConversations} createGroupData={createGroupData}/>


//     </>
//   );
// }


import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';

// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import FormProvider, {
  RHFUploadAvatar,
  RHFTextField
} from 'src/components/hook-form';

import { searchContacts } from 'src/redux/features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllConversation } from 'src/redux/features/chats/chatSlice';
import { useCollapseNav } from './hooks';
import ChatNavItem from './chat-nav-item';
import ChatNavAccount from './chat-nav-account';
import { ChatNavItemSkeleton } from './chat-skeleton';
import ChatNavSearchResults from './chat-nav-search-results';
import ChatAddMember from './chat-add-members';

const NAV_WIDTH = 320;
const NAV_COLLAPSE_WIDTH = 96;

export default function ChatNav({ loading, conversations = [], selectedConversationId, singleConversation, setConversations, viewContact }) {
  const [searchcontacts, setsearchcontacts] = useState({ query: '', results: [] });
  const [openDialog, setOpenDialog] = useState(false);
  const [addMemberDialog, setAddMemberDialog] = useState(false);
  const [createGroupData, setCreateGroupData] = useState(null);
  const [lastObservedMultiple, setLastObservedMultiple] = useState(0);
  const [limit, setLimit] = useState(20);


  const dispatch = useDispatch();
  const observerRef = useRef(null);

  const { currentUser, contacts } = useSelector((state) => state.auth);
  const allContacts = contacts?.body;

  useEffect(() => {
    dispatch(getAllConversation(limit));
  }, [dispatch, limit]);

  const increaseLimit = (multiple) => {
    setLastObservedMultiple((prevLastObserved) => {
      if (multiple > prevLastObserved) {
        setLimit((prevLimit) => prevLimit + 20);
        return multiple;
      }
      return prevLastObserved;
    });
  };

  const NewUserSchema = Yup.object().shape({
    avatarUrl: Yup.mixed().nullable(),
    title: Yup.string().required('title is required'),
  });

  const defaultValues = useMemo(
    () => ({
      avatarUrl: null,
      title: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const { setValue, handleSubmit, reset, formState: { isSubmitting } } = methods;

  useEffect(() => {
    if (currentUser?.accesstoken) {
      dispatch(searchContacts({ token: currentUser.accesstoken, search: searchcontacts.query }));
    }
  }, [dispatch, currentUser, searchcontacts.query]);

  const theme = useTheme();
  const router = useRouter();
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  const {
    collapseDesktop,
    onCloseDesktop,
    onCollapseDesktop,
    openMobile,
    onOpenMobile,
    onCloseMobile,
  } = useCollapseNav();

  useEffect(() => {
    if (!mdUp) {
      onCloseDesktop();
    }
  }, [onCloseDesktop, mdUp]);

  const handleToggleNav = useCallback(() => {
    if (mdUp) {
      onCollapseDesktop();
    } else {
      onCloseMobile();
    }
  }, [mdUp, onCloseMobile, onCollapseDesktop]);

  const handleClickCompose = useCallback(() => {
    if (!mdUp) {
      onCloseMobile();
    }
    router.push(paths.dashboard.chat);
  }, [mdUp, onCloseMobile, router]);

  const handleSearchContacts = useCallback((inputValue) => {
    setsearchcontacts((prevState) => ({
      ...prevState,
      query: inputValue,
    }));

    if (inputValue) {
      const results = allContacts?.filter((contact) =>
        contact.name.toLowerCase().includes(inputValue) ||
        contact.email.toLowerCase().includes(inputValue)
      );

      setsearchcontacts((prevState) => ({
        ...prevState,
        results,
      }));
    }
  }, [allContacts]);

  const handleClickAwaySearch = useCallback(() => {
    setsearchcontacts({
      query: '',
      results: [],
    });
  }, []);

  const handleClickResult = useCallback(
    async (result) => {
      handleClickAwaySearch();
      dispatch(getAllConversation());
      if (viewContact) {
        const pathName = window.location.pathname;
        navigate(`${pathName}`, { state: { id: result } });
        return;

      }

      router.push(`${paths.dashboard.chat}?id=${result}`);
    },
    [handleClickAwaySearch, router, dispatch, viewContact, navigate]
  );

  const handleOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = async () => {
    reset();
    setOpenDialog(false);
  };

  const handleOpenAddMemberDialog = useCallback(() => {
    setAddMemberDialog(true);
  }, []);

  const handleCloseAddMemberDialog = useCallback(() => {
    setAddMemberDialog(false);
  }, []);

  const onSubmit = async (data) => {
    setCreateGroupData(data);
    handleCloseDialog();
    handleOpenAddMemberDialog();
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('avatarUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const setupObserver = useCallback((node, multiple) => {
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries?.forEach((entry) => {
            if (entry.isIntersecting) {
              increaseLimit(multiple);
            }
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
        }
      );
    }
    if (node) {
      observerRef.current.observe(node);
    }
  }, []);

  const renderToggleBtn = (
    <IconButton
      onClick={onOpenMobile}
      sx={{
        left: 0,
        top: 84,
        zIndex: 9,
        width: 32,
        height: 32,
        position: 'absolute',
        borderRadius: `0 12px 12px 0`,
        bgcolor: theme.palette.primary.main,
        boxShadow: theme.customShadows.primary,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          bgcolor: theme.palette.primary.darker,
        },
      }}
    >
      <Iconify width={16} icon="solar:users-group-rounded-bold" />
    </IconButton>
  );

  const renderSkeleton = (
    <>
      {[...Array(12)]?.map((_, index) => (
        <ChatNavItemSkeleton key={index} />
      ))}
    </>
  );

  const renderList = (
    <>
      {Array.isArray(conversations) && conversations.map((conversation, index) => {
        const isMultipleOfTwelve = (index + 1) % 12 === 0;
        const multiple = (index + 1) / 12;
        return (
          <ChatNavItem
            key={conversation?.conversation_id}
            collapse={collapseDesktop}
            conversation={conversation}
            selected={conversation?.conversation_id === selectedConversationId}
            onCloseMobile={onCloseMobile}
            viewContact={viewContact}
            ref={isMultipleOfTwelve ? (node) => setupObserver(node, multiple) : null}
          />
        );
      })}
    </>
  );

  const renderListResults = (
    <ChatNavSearchResults
      query={searchcontacts?.query}
      results={searchcontacts?.results}
      onClickResult={handleClickResult}
    />
  );


  const renderSearchInput = (
    <ClickAwayListener onClickAway={handleClickAwaySearch}>
      <TextField
        fullWidth
        value={searchcontacts.query}
        onChange={(event) => handleSearchContacts(event.target.value)}
        placeholder="Search contacts..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
        sx={{ mt: 2.5 }}
      />
    </ClickAwayListener>
  );

  const renderContent = (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ p: 2.5, pb: 0 }}>
        {!collapseDesktop && (
          <>
            <ChatNavAccount />
            <Box sx={{ flexGrow: 1 }} />
          </>
        )}

        <IconButton onClick={handleOpenDialog}>
          <Iconify width={24} icon="ic:round-group-add" />
        </IconButton>
      </Stack>

      <Box sx={{ p: 2.5, pt: 0 }}>{!collapseDesktop && renderSearchInput}</Box>

      <Scrollbar sx={{ pb: 1 }}>
        {searchcontacts.query && renderListResults}

        {!searchcontacts.query && !!conversations.length && renderList}
      </Scrollbar>
    </>
  );

  return (
    <>
      {!mdUp && renderToggleBtn}

      {mdUp ? (
        <Stack
          sx={{
            height: 1,
            flexShrink: 0,
            width: NAV_WIDTH,
            borderRight: `solid 1px ${theme.palette.divider}`,
            transition: theme.transitions.create(['width'], {
              duration: theme.transitions.duration.shorter,
            }),
            ...(collapseDesktop && {
              width: NAV_COLLAPSE_WIDTH,
            }),
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openMobile}
          onClose={onCloseMobile}
          slotProps={{
            backdrop: { invisible: true },
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {/* Dialog for creating a new group */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xs" sx={{}} fullWidth>
        <DialogTitle sx={{ pb: 0, textAlign: 'center' }}>New Group Chat</DialogTitle>
        <DialogContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" alignItems="center" spacing={5} sx={{ marginTop: '20px' }}>
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: '50%',
                  bgcolor: theme.palette.grey[200],
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <RHFUploadAvatar name="avatarUrl" onDrop={handleDrop} />
              </Box>
              <RHFTextField fullWidth name="title" label="Group name" variant="outlined" />
              <IconButton
                variant="contained"
                type="submit"
                sx={{
                  alignSelf: 'flex-end',
                  bgcolor: 'black',
                  '&:hover': {
                    bgcolor: 'black',
                  },
                  marginBottom: '20px',
                }}
              >
                <Iconify width={24} icon="mdi:arrow-right" sx={{ color: 'white' }} />
              </IconButton>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>

      {/* Dialog for adding members */}
      <ChatAddMember
        open={addMemberDialog}
        setOpen={setAddMemberDialog}
        groupData={createGroupData}
        setConversations={setConversations}
      />
    </>
  );
}

// };
ChatNav.propTypes = {
  conversations: PropTypes.array,
  loading: PropTypes.bool,
  selectedConversationId: PropTypes.string,
  singleConversation: PropTypes.object,
  setConversations: PropTypes.func,
  viewContact: PropTypes.bool,
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import activityServices from './activityServices';

const initialState = {
  activities: [],
  taskActivites: [],
  boardActivites: [],
  totalRecord: 0,
  totalPages: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const getActivities = createAsyncThunk('getActivities', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await activityServices.activityGet(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const activityLikeToggle = createAsyncThunk('activityLikeToggle', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await activityServices.activityLikeToggle(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const activityAddComment = createAsyncThunk('activityAddComment', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await activityServices.activityAddComment(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const activityUpdateComment = createAsyncThunk(
  'activityUpdateComment',
  async ({ commentId, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await activityServices.activityUpdateComment(user.accesstoken, commentId, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const activityDeleteComment = createAsyncThunk(
  "activityDeleteComment",
  async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await activityServices.activityDeleteComment(user.accesstoken, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const taskActivityGet = createAsyncThunk('taskActivityGet', async (taskId, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await activityServices.taskActivityGet(user.accesstoken, taskId);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const boardActivityGet = createAsyncThunk('boardActivityGet', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await activityServices.boardActivityGet(user.accesstoken, data.board, data.page, data.limit);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.activities = action.payload.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPages = action?.payload?.total_pages;
        state.isLoading = false;
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.activities = [];
      })
      .addCase(activityLikeToggle.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(activityLikeToggle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activities = state.activities.map((activity) =>
          activity?._id === action.payload.body._id ? action.payload.body : activity
        );
      })
      .addCase(activityLikeToggle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activityAddComment.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(activityAddComment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activities = state.activities.map((activity) =>
          activity?._id === action.payload.body._id ? action.payload.body : activity
        );
      })
      .addCase(activityAddComment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activityUpdateComment.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(activityUpdateComment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activities = state.activities.map((activity) =>
          activity?._id === action.payload.body._id ? action.payload.body : activity
        );
      })
      .addCase(activityUpdateComment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activityDeleteComment.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(activityDeleteComment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activities = state.activities.map((activity) =>
          activity?._id === action.payload.body._id ? action.payload.body : activity
        );
      })
      .addCase(activityDeleteComment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(taskActivityGet.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(taskActivityGet.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.taskActivites = action.payload.body;
        state.isLoading = false;
      })
      .addCase(taskActivityGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.taskActivites = [];
      })
      .addCase(boardActivityGet.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(boardActivityGet.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.boardActivites = action.payload.body;
        state.isLoading = false;
      })
      .addCase(boardActivityGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.boardActivites = [];
      })
  },
});
export const { resets } = activitySlice.actions;

export default activitySlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import boardServices from "./boardServices";

const initialState = {
  boards:[],
  activeBoards:[],
  singleBoardData:{},
  report:{},
  favoriteBoard:[],
  dueDateBoard:[],
  userBoard:[],
  labelReports:[],
  totalPage:0,
  totalRecord:0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isaddLoading: false,
  message: "",
};


export const addBoard = createAsyncThunk(
    "addBoard",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.addBoard(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const boardGet = createAsyncThunk(
    "boardGet",
    async ({project,pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.boardGet(user.accesstoken,project,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const userBoardGet = createAsyncThunk(
    "userBoardGet",
    async (project,thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.userBoardGet(user.accesstoken,project);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const updateBoard = createAsyncThunk(
    "updateBoard",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.updateBoard(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const activeBoardGet = createAsyncThunk(
    "activeBoardGet",
    async ({pageno,limit,search},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.activeBoardGet(user.accesstoken,pageno,limit,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const singleBoardGet = createAsyncThunk(
    "singleBoardGet",
    async (board,thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.singleBoardGet(user.accesstoken,board);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const transferBoard = createAsyncThunk(
    "transferBoard",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.transferBoard(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const reportBoardGet = createAsyncThunk(
    "reportBoardGet",
    async (board,thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.reportBoardGet(user.accesstoken,board);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const favoriteBoardGet = createAsyncThunk(
    "favoriteBoardGet",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.favoriteBoardGet(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const boardLikeToggle = createAsyncThunk('boardLikeToggle', async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await boardServices.boardLikeToggle(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
});

export const boardTaskDueDateGet = createAsyncThunk(
    "boardTaskDueDateGet",
    async (boardId,thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await boardServices.boardTaskDueDateGet(user.accesstoken,boardId);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const reportBoardLabelGet = createAsyncThunk(
  "reportBoardLabelGet",
  async (board,thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await boardServices.reportBoardLabelGet(user.accesstoken,board);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
  
export const sharedBoard = createAsyncThunk(
  "sharedBoard",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await boardServices.sharedBoard(user.accesstoken,data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateBoardTaskDueDate = createAsyncThunk('updateTask', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await boardServices.updateBoardTaskDueDate(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const boardSlice = createSlice({
  name: "board",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isaddLoading=false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBoard.pending, (state) => {
        state.isaddLoading = true;
      })
      .addCase(addBoard.fulfilled, (state, action) => {
        state.isaddLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.boards = state.boards.concat({ ...action?.payload?.body,project:action?.payload?.body?.project?._id});
      })
      .addCase(addBoard.rejected, (state, action) => {
        state.isaddLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(boardGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(boardGet.fulfilled, (state, action) => {
        state.boards = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPage = action?.payload?.total_pages;
        state.isLoading = false;
      })
      .addCase(boardGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.boards = [];
      })
      .addCase(userBoardGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userBoardGet.fulfilled, (state, action) => {
        state.userBoard = action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(userBoardGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userBoard = [];
      })
      .addCase(updateBoard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBoard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.boards = state.boards.map((board) =>
          board?._id === action.payload.body._id ? { ...action?.payload?.body,project:action?.payload?.body?.project?._id} : board
        );
      })
      .addCase(updateBoard.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeBoardGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeBoardGet.fulfilled, (state, action) => {
        state.activeBoards = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPage = action?.payload?.total_pages;
        state.isLoading = false;
      })
      .addCase(activeBoardGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.activeBoards = [];
      })
      .addCase(singleBoardGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(singleBoardGet.fulfilled, (state, action) => {
        state.singleBoardData = action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(singleBoardGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.singleBoardData = {};
      })
      .addCase(transferBoard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(transferBoard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.boards = state.boards.filter((board) =>
          board?._id !== action.payload.body._id);
      })
      .addCase(transferBoard.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(reportBoardGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reportBoardGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.report = action.payload.body;
      })
      .addCase(reportBoardGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(favoriteBoardGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(favoriteBoardGet.fulfilled, (state, action) => {
        state.favoriteBoard = action?.payload?.body;
        state.isError = false;
        state.isLoading = false;
      })
      .addCase(favoriteBoardGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.favoriteBoard = [];
      })
      .addCase(boardLikeToggle.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(boardLikeToggle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(boardLikeToggle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(boardTaskDueDateGet.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(boardTaskDueDateGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.dueDateBoard=action?.payload?.body;
      })
      .addCase(boardTaskDueDateGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(reportBoardLabelGet.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(reportBoardLabelGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.labelReports = action.payload.body;
      })
      .addCase(reportBoardLabelGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(sharedBoard.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(sharedBoard.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(sharedBoard.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateBoardTaskDueDate.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateBoardTaskDueDate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.dueDateBoard=state.dueDateBoard.map((item) =>
          item?._id === action?.payload?.body?._id
            ? { ...item, dueDate: action?.payload?.body?.dueDate}
            : item
        )
      })
      .addCase(updateBoardTaskDueDate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
  },
});
export const { resets } = boardSlice.actions;

export default boardSlice.reducer;

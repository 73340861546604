import { IconButton, useTheme, Typography, Box, Stack } from '@mui/material';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSearchParams } from 'src/routes/hooks';
import ChatNav from 'src/sections/chat/chat-nav';
import { m } from 'framer-motion';
import { io } from 'socket.io-client';
import { formatToReadableDateTime } from 'src/utils/format-time';
import notificationSound from 'src/assets/audio/message-notification.mp3';
import {
  getAllConversation,
  getConversationAttachmentById,
  getConversationById,
  markAllMessagesAsRead,
} from 'src/redux/features/chats/chatSlice';
import ChatMessageList from 'src/sections/chat/chat-message-list';
import { useLocation, useNavigate } from 'react-router-dom';
import ChatHeaderDetail from 'src/sections/chat/chat-header-detail';
import ChatMessageInput from 'src/sections/chat/chat-message-input';
import { useResponsive } from 'src/hooks/use-responsive';
import { paths } from 'src/routes/paths';

// const ENDPOINT = "ws://localhost:4001"
const host = window.location.hostname;
const ENDPOINT =
  host === 'localhost' ? 'wss://dev.glowingify.com' : `wss://${window.location.hostname}`;

const ChatView = () => {
  const { allConversations, isLoading } = useSelector((state) => state.conversations);
  const [conversations, setConversations] = useState([]);
  const [conversationData, setConversationData] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [socket, setSocket] = useState({});

  const [message, setMessage] = useState('');
  const messageInputRef = useRef(null);

  const searchParams = useSearchParams();
  const { state: selectedId } = useLocation();
  const { singleConversation, isLoading: loading } = useSelector((state) => state.conversations);
  const { currentUser } = useSelector((state) => state.auth);

  const conversation = singleConversation;
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = useBoolean();
  const viewContact = true;
  const isMd = useResponsive('up', 'md');

  // State to control the ChatMessageList modal
  const [isChatMessageModalOpen, setIsChatMessageModalOpen] = useState(false);

  // Set selectedConversationId from location state
  useEffect(() => {
    if (selectedId) {
      setSelectedConversationId(selectedId.id);
      console.log(selectedId)
      setIsChatMessageModalOpen(true); // Open the modal when a conversation is selected
    }
  }, [selectedId]);

  // Fetch all conversations
  useEffect(() => {
    dispatch(getAllConversation());
  }, [dispatch]);

  // Update conversations when allConversations changes
  useEffect(() => {
    if (allConversations?.body?.length > 0) {
      setConversations(allConversations?.body);
    }
  }, [allConversations]);

  const matchedConversation = conversations?.find(
    (con) => con?.conversation_id === selectedConversationId
  );

  // Socket.IO setup
  useEffect(() => {
    const clientSocket = io(ENDPOINT, {
      query: {
        user_id: currentUser?._id,
      },
      transports: ['websocket'],
      reconnection: true,
      secure: true,
      rejectUnauthorized: false,
    });

    setSocket(clientSocket);

    clientSocket.on('message', (incomingMessage) => {
      if (incomingMessage?.conversationId === selectedConversationId) {
        setConversationData((prevData) => {
          const newMessages = {
            message: incomingMessage.payload,
            sender: {
              _id: incomingMessage?.sender?._id,
              name: incomingMessage?.sender?.name,
              image: incomingMessage?.sender?.image,
            },
            timesince: matchedConversation?.timesince,
          };

          return [...prevData, { ...newMessages }];
        });
        setConversations((prevConversations) => {
          const updatedConversation = prevConversations.find(
            (con) => con.conversation_id === selectedConversationId
          );
          if (!updatedConversation) return prevConversations;

          return [
            {
              ...updatedConversation,
              message: incomingMessage?.payload,
              timesince: formatToReadableDateTime(new Date()),
              message_type: incomingMessage?.message_type,
            },
            ...prevConversations.filter((con) => con?.conversation_id !== selectedConversationId),
          ];
        });
        dispatch(markAllMessagesAsRead(selectedConversationId));
      } else {
        dispatch(getAllConversation());
        playNotificationSound();
      }
    });

    return () => {
      clientSocket.disconnect();
    };
  }, [currentUser, matchedConversation, dispatch, selectedConversationId]);

  // Handle conversation selection
  const handleConversation = useCallback(
    async (id) => {
      setMessage('');
      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
      dispatch(getConversationAttachmentById(id));
      dispatch(markAllMessagesAsRead(id));
      const response = await dispatch(getConversationById({ id, page: 1 }));
      const newMessages = response?.payload?.body;
      setConversations((prevConversations) =>
        prevConversations?.map((conv) =>
          conv?.conversation_id === id ? { ...conv, unread_count: 0 } : conv
        )
      );
      if (Array.isArray(newMessages)) {
        setConversationData(newMessages);
      }
    },
    [dispatch]
  );

  // Fetch conversation data when selectedConversationId changes
  useEffect(() => {
    if (selectedConversationId) {
      handleConversation(selectedConversationId);
      navigate(`${window.location.pathname}`);
    }
  }, [handleConversation, selectedConversationId, navigate]);

  const handleClick = (e) => {
    if (e.target.id === 'chatContainer') {
      open.onFalse();
      setSelectedConversationId('');
    }
  };

  const details = !!selectedConversationId;
  const handleAddRecipients = useCallback((selected) => {
    setRecipients(selected);
  }, []);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  // SEND MESSAGE
  const handleSendMessage = (event) => {
    if (message) {
      if (event.key === 'Enter') {
        if (matchedConversation?.chatType === 'group') {
          socket.emit('message', {
            to: matchedConversation?.conversation_id,
            payload: message,
            type: 'text',
          });
        } else {
          socket.emit('message', {
            to:
              matchedConversation?.sender_id === currentUser?._id
                ? matchedConversation.reciever_id
                : matchedConversation.sender_id,
            payload: message,
            type: 'text',
          });
        }
        setConversationData((prevData) => {
          const newMessages = [
            ...prevData,
            {
              message,
              sender: {
                _id: currentUser?._id,
                name:
                  matchedConversation?.sender_name === currentUser?.name
                    ? matchedConversation.reciever_name
                    : matchedConversation.sender_name,
              },
              timestamp: new Date().toISOString(),
              timesince: formatToReadableDateTime(new Date()),
            },
          ];

          return newMessages;
        });
        setConversations((prevConversations) => {
          const updatedConversation = prevConversations.find(
            (con) => con.conversation_id === selectedConversationId
          );
          if (!updatedConversation) return prevConversations;

          return [
            {
              ...updatedConversation,
              message,
              timesince: formatToReadableDateTime(new Date()),
              message_type: 'text',
            },
            ...prevConversations.filter((con) => con?.conversation_id !== selectedConversationId),
          ];
        });
        setMessage('');
      }
    }
  };

  // SEND IMAGE
  const handleSendImage = (fileName, fileType) => {
    if (fileName) {
      if (matchedConversation?.chatType === 'group') {
        socket.emit('message', {
          to: matchedConversation?.conversation_id,
          payload: fileName,
          type: fileType,
        });
        setConversations((prevConversations) => {
          const updatedConversation = prevConversations.find(
            (con) => con.conversation_id === selectedConversationId
          );
          if (!updatedConversation) return prevConversations;
          return [
            {
              ...updatedConversation,
              message: fileName,
              timesince: formatToReadableDateTime(new Date()),
              message_type: fileType,
            },
            ...prevConversations.filter((con) => con?.conversation_id !== selectedConversationId),
          ];
        });
        dispatch(getConversationAttachmentById(selectedConversationId));
      } else {
        socket.emit('message', {
          to:
            matchedConversation?.sender_id === currentUser?._id
              ? matchedConversation.reciever_id
              : matchedConversation.sender_id,
          payload: fileName,
          type: fileType,
        });
        setConversations((prevConversations) => {
          const updatedConversation = prevConversations.find(
            (con) => con.conversation_id === selectedConversationId
          );
          if (!updatedConversation) return prevConversations;
          return [
            {
              ...updatedConversation,
              message: fileName,
              timesince: formatToReadableDateTime(new Date()),
              message_type: fileType,
            },
            ...prevConversations.filter((con) => con.conversation_id !== selectedConversationId),
          ];
        });
        dispatch(getConversationAttachmentById(selectedConversationId));
      }
    }
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      flexShrink={0}
      sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 72 }}
    >
      {selectedConversationId
        ? details && <ChatHeaderDetail conversation={matchedConversation} />
        : ''}
    </Stack>
  );

  return (
    <div>
      {/* Chat toggle button */}
      <IconButton
        onClick={() => (isMd ? open.onToggle() : navigate(`${paths.dashboard.chat}`))}
        sx={{
          position: 'fixed',
          bottom: 10,
          right: 20,
          zIndex: 1000,
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          borderRadius: '50%',
          padding: '12px',
          boxShadow: 2,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            boxShadow: 6,
          },
          transition: 'all 0.3s ease', // smooth transition
        }}
      >
        <Iconify icon="eva:message-square-fill" width={24} height={24} />
      </IconButton>
      {open.value && (
        <Box
          sx={{ position: 'fixed', top: 0, left: 0, width: '100%' }}
          id="chatContainer"
          onClick={handleClick}
        >
          {/* ChatNav component */}
          {isMd && (
            <m.div
              style={{
                position: 'fixed',
                bottom: 70,
                right: 20,
                zIndex: 1000,
                width: '320px',
                height: '500px',
                backgroundColor: 'white',
                borderRadius: '12px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
            >
              <ChatNav
                conversations={conversations}
                loading={isLoading}
                selectedConversationId={selectedConversationId}
                singleConversation={conversation}
                setConversations={setConversations}
                viewContact={viewContact}
              />
            </m.div>
          )}

          {/* Modal for ChatMessageList */}
          {selectedConversationId && isMd && (
            <m.div
              style={{
                position: 'fixed',
                bottom: 70,
                right: 350,
                zIndex: 1000,
                width: '400px',
                height: '500px',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                borderRadius: '12px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
            >
              {/* Fixed Header */}
              <div
                style={{
                  borderBottom: '1px solid #ddd',
                  position: 'sticky',
                  top: 0,
                  zIndex: 10,
                }}
              >
                {renderHead}
              </div>

              {/* Scrollable Messages */}
              <div
                style={{
                  flex: 1, // Takes all available space
                  overflowY: 'auto',
                  padding: '10px',
                }}
              >
                <ChatMessageList messages={conversationData} participants={[]} />
              </div>

              {/* Fixed Input Box */}
              <div
                style={{
                  padding: '10px',
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 10,
                }}
              >
                <ChatMessageInput
                  ref={messageInputRef}
                  recipients={recipients}
                  onAddRecipients={handleAddRecipients}
                  selectedConversationId={selectedConversationId}
                  disabled={!selectedConversationId}
                  handleSendMessage={handleSendMessage}
                  setMessage={setMessage}
                  handleSendImage={handleSendImage}
                  setConversationData={setConversationData}
                  message={message}
                />
              </div>
            </m.div>
          )}
        </Box>
      )}
    </div>
  );
};

export default ChatView;

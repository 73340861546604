// ----------------------------------------------------------------------

export default function useGetMessage({ message, sender, currentUserId,message_type }) {
  // const sender = participants.find((participant) => participant === message.sender);

  const senderDetails =
    sender?._id === currentUserId
      ? {
          type: 'me',
        }
      : {
          avatarUrl: sender?.image,
          firstName: sender?.name,
        };

  const me = senderDetails?.type === 'me';

  const hasImage = message_type === 'image';
  const hasVideo = message_type === 'video';
  const hasFile = message_type === 'file';

  return {
    hasImage,
    hasVideo,
    hasFile,
    me,
    senderDetails,
  };
}
